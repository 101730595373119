<div style="position: relative; height: 100%; width: 100%">
  <div
    class=""
    #chartContainer
    id="chartContainer"
    style="
      background-size: 20px 20px;
      background-image: radial-gradient(circle, #6c6464 1px, rgba(0, 0, 0, 0) 1px);
      border: 1px solid var(--surface-900);
      border-radius: var(--border-radius);
      min-height: 75vh;
      height: 84vh;
      overflow: hidden;
    "
  ></div>
  <!-- <div class="filter-buttons">
  <button
    (click)="onFilterButtonClick($event)"
    class="btn btn-action-button waves-effect waves-light"
    >
    <i class="fas fa-filter"></i> Filters
  </button>
</div> -->
  @if (showOtherActions) {
    <div class="action-buttons">
      <!-- <button
    (click)="chart.setExpanded('O-6164').render()"
    class="btn btn-action-button waves-effect waves-light"
    >
    <i class="fas fa-chevron-down"></i> Expand
  </button>
  <br />
  <button
    (click)='chart.setExpanded("O-6164",false).render()'
    class="btn btn-action-button waves-effect waves-light"
    >
    <i class="fas fa-chevron-up"></i> Collapse</button
    ><br />
    <button
      (click)='chart.addNode({imageUrl:"https:\/\/raw.githubusercontent.com/bumbeishvili/Assets/master/Projects/D3/Organization%20Chart/cto.jpg",id:"root child",parentId:"O-6066",name:"test",progress:[25,20,15,10]})'
      class="btn btn-action-button waves-effect waves-light"
      >
      <i class="fas fa-folder-plus"></i> Add Node
    </button>
    <br />
    <button
      (click)='chart.removeNode("O-6067")'
      class="btn btn-action-button waves-effect waves-light"
      >
      <i class="fas fa-user-times"></i> remove</button
      ><br /> -->
      <button (click)="chart.fit()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-sync"></i> fit
      </button>
      <br />
      <button (click)="swap()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-retweet"></i> swap
      </button>
      <br />
      <button (click)="onCompact()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-sitemap"></i> compact
      </button>
      <br />
      <button (click)="toggleCenterActive()" class="btn btn-action-button waves-effect waves-light">
        <i [class]="this.actNdCent % 2 == 0 ? ' fas fa-circle ' : 'far fa-circle '"></i> center Active:
        {{ this.actNdCent % 2 == 0 ? 'On' : 'Off' }}
      </button>
      <br />
      <button (click)="chart.setCentered('root').render()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-compress-arrows-alt"></i> center
      </button>
      <br />
      <!-- <button
      (click)='chart.setHighlighted("O-6162").render()'
      class="btn btn-action-button waves-effect waves-light"
      >
      <i class="fas fa-highlighter"></i> mark</button
      ><br />
      <button
        (click)='chart.setUpToTheRootHighlighted("O-6162").render().fit()'
        class="btn btn-action-button waves-effect waves-light"
        >
        <i class="fas fa-route"></i> mark root
      </button>
      <br />
      <button
        (click)="chart.clearHighlighting()"
        class="btn btn-action-button waves-effect waves-light"
        >
        <i class="fas fa-eraser"></i> clear mark
      </button>
      <br />
      <button
        (click)="chart.fullscreen('body')"
        class="btn btn-action-button waves-effect waves-light"
        >
        <i class="fas fa-expand"></i> fullscreen</button
        ><br />
        <button
          (click)="chart.exportImg()"
          class="btn btn-action-button waves-effect waves-light"
          >
          <i class="far fa-images"></i> export img
        </button>
        <br />
        <button
          (click)="chart.exportImg({full:true})"
          class="btn btn-action-button waves-effect waves-light"
          >
          <i class="far fa-images"></i> export full img
        </button>
        <br />
        <button
          (click)="chart.exportSvg()"
          class="btn btn-action-button waves-effect waves-light"
          >
          <i class="fas fa-file-download"></i> export svg
        </button>
        <br /> -->
      <button (click)="chart.expandAll()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-angle-double-down"></i> expand all</button
      ><br />
      <button (click)="chart.collapseAll()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-angle-double-up"></i> collapse all</button
      ><br />
      <!-- <button
            (click)="downloadPdf()"
            class="btn btn-action-button waves-effect waves-light"
            >
            <i class="far fa-file-pdf"></i> export pdf
          </button>
          <br />
          <button
            (click)='chart.connections([{from:"O-6069",to:"O-6070",label:"Conflicts of interest"}]).render()'
            class="btn btn-action-button waves-effect waves-light"
            >
            <i class="fas fa-project-diagram"></i> add link
          </button>
          <br /> -->
      <button (click)="chart.zoomOut()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-minus"></i> zoom out</button
      ><br />
      <button (click)="chart.zoomIn()" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-plus"></i> zoom in
      </button>
      <br />
      <button (click)="showOtherActions = false" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-chevron-down"></i> Hide Actions
      </button>
      <br />
    </div>
  }
  @if (!showOtherActions) {
    <div class="action-buttons">
      <button (click)="showOtherActions = true" class="btn btn-action-button waves-effect waves-light">
        <i class="fas fa-chevron-up"></i> Show Actions
      </button>
    </div>
  }
</div>
<!-- <div class="tooltip"></div> -->
